.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(71, 86, 88);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sv_qstn .sq-root {
  border: 1px solid gray;
  border-left: 4px solid #18a689;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 30px;
}

.addButton {
  padding-left: 2%;
  padding-top: 2%;
}

.flexContainer {
  display: flex;
  justify-content: center;
  column-gap: 100px;
  row-gap: 100px;
}
.flexColumn {
  flex-direction: column;
}
.flexStart {
  justify-content: flex-start;
}
.flexCenter {
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.titleContainer {
  position: relative; /* This enables absolute positioning for children */
  display: flex; /* Use flexbox for centering */
  justify-content: left; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 300px; /* Adjust as needed */
}

.titleBackgroundImage {
  background-image: url('../src/static/diginet_banner_logo.jpg'); /* Your image path */
  background-size: cover;
  background-repeat: no-repeat;
  /* Cover the container area completely */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1; /* Behind the Paper */
}

.TitlePaperStyle {
  margin-top: 5%;
  margin-left: 5%;
  max-width: 25vh;
  max-height: 7vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 3%;
  z-index: 2; /* Above the background image */
  /* No need to absolutely position it now since flexbox is centering it */
  /* Add max-width or padding if you want to control its width */
}

.textStyle {
  font-size: clamp(1.5rem, 3vw, 1.5rem);
  word-break: break-word;
  margin: 0;
  margin-bottom: 5%;
}


@media (max-width: 768px) {

  .textStyle {
    font-size: clamp(1rem, 2vw, 1rem);
    word-break: break-word;
    margin: 0;
    margin-bottom: 5%;
  }

  .titleContainer {
    height: 25vh; /* Or set to a specific height that works for your design */
    justify-content: center;
  }

  .titleBackgroundImage {
    /* Optional: You might want to adjust background positioning for mobile */
    background-position: center;
  }

  .TitlepaperStyle {
    margin: 10px; /* Adds some space around the Paper component */
    width: auto; /* Adjust width as necessary */
    /* More styles here if you need to adjust padding, max-width, etc. */
  }
}